.chakra-accordion > .customAccordionItem:first-child {
    border-radius: 15px 15px 0px 0px;
    border-top: unset;
}

.chakra-accordion > .customAccordionItem:last-child {
    border-radius: 0px 0px 15px 15px;
    border-bottom: unset;
}

.chakra-accordion > .customAccordionItem {
    border-radius: 0px 0px 0px 0px;
    background-color: rgba(0, 0, 0, 1);
}

.chakra-accordion:has(.customAccordionItem) {
    border: 1px solid rgba(34, 34, 34, 1);
    margin-bottom: 15px;
    border-radius: 15px;
}

.chakra-collapse:has(.customTable) {
    background-color: rgba(34, 34, 34, 1);
}

.customAccordionItem:first-child [aria-expanded=true] {
    border-radius: 15px 15px 0px 0px;
}

.customAccordionItem:last-child .chakra-collapse:has(.customTable) {
    border-radius: 0px 0px 15px 15px;
}

.customtable-line {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}

.customAccordionTag {
    border: 1px solid #E41E20;
    background: #e41e2126;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 3px;
}

.customAccordionTag:hover {
    text-decoration: none!important;
    background: #e41e214d;
}

:has(>.customAccordionTag) {
    column-gap: 12px;
}

/* .customTable td > div {
    border-style: solid !important;
    border-width: 3px;
    border-image: radial-gradient(circle, rgba(17, 17, 17,1) 0%, rgba(17, 17, 17,1) 96%, rgba(0,0,0,0) 96%) 1 !important;
    display: block;
} */