.dashboardWrapper {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 39px;
}

.chartWrapper {
    background: rgba(34, 34, 34, 1);
    width: 100%;
    border-radius: 15px;
    height: 100%;
}

.chartDisplay {
    margin: var(--chakra-space-4);
    outline: 2px solid transparent;
    outline-offset: 2px;
    /* background: #00000066; */
    padding: 12px;
    width: auto!important;
}

.recharts-wrapper > svg {
    overflow: visible;
}

@media (max-width: 768px) {
    .dashboardWrapper {
        flex-direction: column;
        align-items: center;

        &.chartWrapper {
            width: 100%;
        }
    }
}