body {
    background-color: #000;
}

input:focus-visible {
    border: 1px solid #636363!important;
    box-shadow: 0 0 0 0!important;
}

input::placeholder {
    color: #A7A7A7
}

#root {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;
}

#root > div:first-child {
    width: 100%;
}

.pagination-wrapper button {
    border: 1px solid;
    border-radius: 15px;
    border-color: rgb(34, 34, 34);
    margin: 0px 0px;
    padding: 18px 18px;
    padding: 24px 30px;
    background-color: var(--chakra-colors-blackAlpha-50)
}

.pagination-wrapper div > button {
    border: 0px solid;
    margin: 0px 9px;
}

.data-logs-pagination-wrapper button {
    border-radius: 15px;
    padding: 18px 18px;
    padding: 24px 30px;
    margin-top: 15px;
    margin-left: 3px;
    margin-right: 3px;
}