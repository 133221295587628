.searchWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tableWrapper {
    grid-template-columns: 1fr 1fr;
    row-gap: 27px;
}

.tableWrapper > div {
    width: 96%;
    overflow: auto;
    max-height: 300px;
}

.tableWrapper > div::-webkit-scrollbar
{
	width: 3px;
    height: 3px;
	background-color: rgba(228, 30, 32, 0.18);
}

.tableWrapper > div::-webkit-scrollbar-thumb
{
	background-color: rgba(228, 30, 32, 0.18);
}

.tableWrapper th {
    color: #ffffff99
}

.tableWrapper td {
    color: #ffffff99;
    padding: 9px 12px;
    font-size: 12px;
}

.nav-wrapper input {
    height: 40px;
}

.line-graph-type1 {
    background: #222222;
    border-radius: 16px;
    padding: 30px 15px 0px 0px
}

.line-graph-type1 .recharts-cartesian-axis {
    background: #222222;
    border-radius: 16px;
}

.line-graph-type1 .recharts-cartesian-axis-tick-value {
    font-size: 12px;
}

@media (max-width: 768px) {
    .tableWrapper {
        grid-template-columns: 1fr;

        & > div {
            margin-top: 15px;
            width: 100%;
        }
    }
}