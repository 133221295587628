@keyframes pulseEffect {
    0% {
      box-shadow: 0 0 12px 6px rgba(255, 0, 0, 0.7);
    }
    70% {
      box-shadow: 0 0 12px 9px rgba(255, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0px rgba(255, 0, 0, 0);
    }
  }
  
  .pulse {
    animation: pulseEffect 1.2s ease-out; /* No 'infinite' here */
  }
  