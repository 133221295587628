.cvePage-pies-wrapper > div {
    width: 45%;
}

@media (max-width: 425px) {
    .cvePage-pies-wrapper > div {
        width: 100%!important;
    }

    .infoComponentWrapper .cveTotalAttempts {
        flex-direction: column;
    }

    :has(>.custom-accordion-title) > *:not(.custom-accordion-title) {
        display: none;
    }

    .cvePage-pies-wrapper {
        flex-direction: column;
        row-gap: 30px;
    }
    
    .custom-accordion-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 192px;
    }

    .pagination-wrapper > div > button {
        display: none;
    }

    .searchQueryTitle {
        font-size: 30px!important;
    }
}